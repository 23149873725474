import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import RaceBook from "../assets/RaceBookURDB.pdf"
import styled from "styled-components"
import Lista_startowa from "../assets/Lista_startowa.pdf"

const Title = styled.div`
    text-align: center;
    padding: 20px 5px;


    
    `
const ButtonZapisy = styled.button`
    margin:  10px auto;
    color: whitesmoke;
    background-color: #B68A65;
    font-size: 1.3rem;
    font-weight: lighter;
    /* border: 10px solid white; */
    /* border-radius: 25px; */
    border: none;
    padding: 15px 25px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.4s linear;
    /* width: 200px; */
    margin-bottom: 50px;
   

        
    &:hover{
        /* border: 10px solid #B68A65; */
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    }
`
const NotFoundPage = () => {
  return (
    
    <Layout>
      <Title>
        <h2>Nie ma takiej strony</h2>
      {/* <h2>
        Ultra Race Dolina Bugu RaceBook
      </h2>
      <Link href={RaceBook} title="RaceBook>">
                <ButtonZapisy>RaceBook</ButtonZapisy>
      </Link>
      <h2>
        Ultra Race Dolina Bugu Lista Startowa
      </h2>
      <Link href={Lista_startowa} title="Lista_startowa">
                <ButtonZapisy>Lista Startowa</ButtonZapisy>
      </Link> */}
      </Title>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
